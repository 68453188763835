<template>
  <div class="vg_wrapper">
    <el-card>
      <publicTips></publicTips>
      <div class="vd_search_group">
        <el-form label-width="120px" :inline="true" size="min" :model="searchForm" ref="searchForm"
                 @keyup.enter.native="getRequsNow()">
          <el-row>
            <el-col :md="8">
              <el-form-item label="委托单号:">
                <el-input size="small" v-model.trim="searchForm.requ_no" clearable
                          placeholder="请填写委托单号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="客户名称:">
                <el-input size="small" v-model.trim="searchForm.cust_name" clearable
                          placeholder="请填写客户名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="客户国家:">
                <el-input size="small" v-model.trim="searchForm.cust_country" clearable
                          placeholder="请填写客户国家"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="8">
              <el-form-item label="单据状态:">
                <el-select v-model="searchForm.requ_status" placeholder="请选择" size="small" clearable>
                  <el-option v-for="item in requStaList" :key="item.value" :label="item.label"
                             :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="打样部门:">
                <selectSectionType ref="selectSectionType" @selectStatusRow="getStatusVal2"/>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="打样接收人:" prop="requ_receive">
                <el-select filterable v-model="searchForm.requ_receive" placeholder="请选择打样接收人" size="small"
                           clearable>
                  <el-option v-for="item in stffType" :key="item.user_id" :label="item.stff_name"
                             :value="item.stff_name"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="16">
              <el-form-item label="要求交样时间:">
                <div class="block vg_mr_32">
                  <el-date-picker
                      size="small"
                      v-model="searchForm.timeValue"
                      type="datetimerange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="录入人:" prop="stff_name">
                <el-select filterable v-model="searchForm.stff_name" placeholder="请选择录入人" size="small" clearable>
                  <el-option v-for="item in stffType" :key="item.user_id" :label="item.stff_name"
                             :value="item.stff_name"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="24">
              <el-form-item label="创建时间:">
                <div class="block vg_mr_32">
                  <el-date-picker
                      size="small"
                      v-model="searchForm.timeValue1"
                      type="datetimerange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary" icon="el-icon-search" @click="getRequsNow()" class="vg_ml_16">
                  查询
                </el-button>
                <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"
                ><i class="el-icon-refresh-right"></i> 刷新
                </el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="vd_button_group vg_mtb_16">
        <el-row>
          <el-col :md="12">
            <!--            <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i>-->
            <!--              新增-->
            <!--            </el-button>-->
            <!--            <el-button type="danger" size="small" @click="doDelete()" :disabled="!btn.delete"-->
            <!--            ><i class="el-icon-delete"></i> 删除-->
            <!--            </el-button-->
            <!--            >-->
            <el-button type="primary" size="small" @click="syncDataDialogVisible = true"><i></i> 同步工厂打样单
            </el-button>
            <el-dialog title="同步工厂打样单" width="70%" top="0" :visible.sync="syncDataDialogVisible"
                       @opened="getSyncData">
              <el-date-picker
                  class="vg_mr_8 topDateRangeSearch"
                  size="small"
                  v-model="dateRange"
                  type="daterange"
                  value-format="timestamp"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
              />
              <el-button type="primary" size="small" icon="el-icon-search" @click="getSyncData()"> 查询</el-button>
              <el-button type="info" size="small" class="vd_export" @click="syncDataRefresh"
                         icon="el-icon-refresh-right">刷新
              </el-button>
              <search-table
                  style="margin-top: 8px"
                  ref="searchTable"
                  :data="syncTableData"
                  :tableRowKey="'autoInc'"
                  :columns="syncTableProperties"
                  v-loading="syncLoadFlag"
                  :totalPage="syncDataTotalPage"
                  :need-fixed-height="true"
                  @row-dblclick="row => jumpAddNewSyncData(row)"
                  @getTableData="getSyncData"
              >
              </search-table>
            </el-dialog>
          </el-col>
          <el-col :md="12" class="vd_total">
            <span>样品数量:{{ smplTotal }}</span>
            <span class="vd_left">打样样品数量:{{ smplNumTotal }}</span>
          </el-col>
        </el-row>
      </div>
      <el-row>
        <el-col :md="24">
          <el-table
              ref="multiTable"
              v-loading="loadFlag"
              class="vg_cursor"
              :data="tableData"
              @sort-change="sortChange"
              @selection-change="handleSelectionChange"
              @select="selectRows"
              @select-all="selectRows"
              @row-dblclick="dbClickJp"
              border
          >
            <el-table-column type="selection" width="48" align="center"/>
            <el-table-column label="委托单号" prop="requ_no"/>
            <el-table-column label="工厂打样单号" prop="fequ_no"/>
            <el-table-column label="委托人" sortable prop="requ_stff_name"/>
            <el-table-column label="打样接收人" prop="requ_receive">
              <template v-slot="scope">
                <span v-if="scope.row.requ_receive">
                  {{ scope.row.requ_receive }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="客户名称" prop="cust_abbr">
              <template v-slot="scope">
                <span v-if="scope.row.cust_abbr">
                  {{ scope.row.cust_abbr }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="客户国家" prop="cust_country">
              <template v-slot="scope">
                <span v-if="scope.row.cust_country">
                  {{ scope.row.cust_country }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column sortable label="要求交样时间" prop="requ_deli_time" :formatter="formDeliTime"/>
            <el-table-column label="录入人" prop="stff_name" show-overflow-tooltip :formatter="helper.personCombina"/>
            <el-table-column sortable label="创建时间" prop="create_time" :formatter="formatDate"/>
            <el-table-column label="单据状态" :show-overflow-tooltip="true" align="center">
              <template v-slot="scope">
                <el-tag :type="getStatusName(scope.row.requ_status).type" size="mini"
                >{{ getStatusName(scope.row.requ_status).name }}
                </el-tag>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <pubPagination :totalPage="totalPage" @changePageSearch="changePageSearch"
                         ref="pubPagination"></pubPagination>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import {get, getNoCatch, post} from '@api/request';
import {requAPI} from '@api/modules/requ';
import {userAPI} from '@api/modules/user';
import pubPagination from '@/components/common/pubPagination';
import selectSectionType from '@/views/component/selectSectionType';
import publicTips from '@/views/component/publicTips';
import SearchTable from '@/components/SearchTableV2.vue';
import {getDateNoTime} from '@assets/js/dateUtils';

export default {
  name: 'RequList',
  components: {
    SearchTable,
    pubPagination,
    publicTips,
    selectSectionType
  },
  data() {
    return {
      dateRange: [],
      syncTableData: [],
      syncDataTotalPage: 0,
      syncLoadFlag: false,
      syncTableProperties: [
        {prop: 'index', type: 'index', labelWidth: '50px', label: '序号'},
        {label: '委托打样单号', prop: 'requ_no', itemType: 'input', input: true, sortable: false, widthAuto: true},
        {label: '客户简称', prop: 'cust_abbr', itemType: 'input', input: true, sortable: false, widthAuto: true},
        {label: '录入人', prop: 'stff_name', itemType: 'input', input: true, sortable: false, widthAuto: true},
        {
          label: '录入时间',
          prop: 'create_time',
          itemType: 'date',
          input: false,
          sortable: false,
          widthAuto: true,
          formatter: val => getDateNoTime(val, true)
        }
      ],
      requStaList: [
        {
          value: 0,
          label: '待接收'
        },
        {
          value: 1,
          label: '全部接收'
        },
        {
          value: 2,
          label: '部分接收'
        },
        {
          value: 3,
          label: '部分退回'
        }
      ],
      syncDataDialogVisible: false,
      tableData: [],
      searchForm: {
        requ_no: null,
        requ_stff_id: null,
        status: null,
        stff_name: null,
        requ_receive: null,
        belo_dept_id: null, //打样部门
        requ_sort: null,
        requ_field: null,
        timeValue: {
          startTime: null,
          endTime: null
        },
        timeValue1: {
          startTime: null,
          endTime: null
        },
        requ_status: null
      },
      totalPage: 0,
      btn: {},
      requ_receive: [],
      loading: true,
      multiSelection: [],
      currentPage: 1,
      stffType: [],
      loadFlag: true,
      isDyj: true,
      beloDeptId: null,
      smplTotal: 0,
      smplNumTotal: 0
    };
  },
  created() {
    this.getDepartment();
    this.initData();
  },
  watch: {
    $route(to, from) {
      if (from.path === '/requ_add' || from.path === '/requ_edit') {
        this.initData();
      }
    }
  },
  methods: {
    initData() {
      this.loadFlag = true;
      this.getRequsList();
      this.getStffType();
      // this.getRequsReceive();
      // this.getDepartment()
    },
    //判断是否 是DYJ员工
    getDepartment() {
      let userInfo = this.$cookies.get('userInfo');
      if(userInfo.user_name === 'admin'){
        this.searchForm.belo_dept_id = null;
        this.isDyj = false;
        return;
      }
      if (userInfo.dept_id === 2 || userInfo.dept_id === 17) {
        this.searchForm.belo_dept_id = userInfo.dept_id + '';
        this.beloDeptId = userInfo.dept_id + '';
        this.isDyj = true;
      } else {
        this.searchForm.belo_dept_id = null;
        this.isDyj = false;
      }
    },
    //更改状态
    getStatusName(status) {
      switch (status) {
        case 0:
          return {name: '待接收', type: 'info'};
        case 1:
          return {name: '全部接收', type: 'success', effect: 'Dark'};
        case 2:
          return {name: '部分接收', type: 'success'};
        case 3:
          return {name: '部分退回', type: 'warning', effect: 'Dark'};
        default:
          return {name: '错误', type: 'danger'};
      }
    },
    // 获取打样接收人
    getRequsReceive() {
      get(requAPI.getRequs)
          .then(res => {
            if (res.data.code === 0) {
              this.requ_receive = res.data.data;
            }
          })
          .catch(() => {
          });
    },
    // 获取供应商信息
    getRequsList() {
      const timeNewVal = this.helper.getTime(this.searchForm.timeValue);
      const timeNewVal1 = this.helper.getTime(this.searchForm.timeValue1);
      get(requAPI.getRequs, {
        requ_field: this.searchForm.requ_field,
        requ_sort: this.searchForm.requ_sort,
        requ_no: this.searchForm.requ_no,
        requ_stff_id: this.searchForm.requ_stff_id,
        stff_name: this.searchForm.stff_name,
        requ_receive: this.searchForm.requ_receive,
        // status: this.searchForm.status,
        cust_country: this.searchForm.cust_country,
        cust_name: this.searchForm.cust_name,
        belo_dept_id: this.searchForm.belo_dept_id,
        start_time: timeNewVal.startTime,
        end_time: timeNewVal.endTime,
        start_time2: timeNewVal1.startTime,
        end_time2: timeNewVal1.endTime,
        page_no: this.currentPage,
        requ_status: this.searchForm.requ_status
      })
          .then(res => {
            if (res.data.code === 0) {
              this.loading = false;
              this.tableData = res.data.data.list;
              this.totalPage = res.data.data.total;
              this.smplTotal = res.data.data.smpl_total;
              this.smplNumTotal = res.data.data.smpl_num_total;
              this.btn = res.data.data.btn;
              setTimeout(() => {
                this.loadFlag = false;
              }, 500);
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message: mg, type: tp});
            }
          })
          .catch(res => {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message: mg, type: tp});
          });
    },

    //同步工厂打样单
    PcTodyj() {
    },
    // 获取委托人
    getStffType() {
      get(userAPI.getAllUsersV1)
          .then(res => {
            if (res.data.code === 0) {
              let stffType = res.data.data;
              let map = new Map();
              for (let item of stffType) {
                map.set(item.stff_name, item);
              }
              stffType = [...map.values()];
              this.stffType = stffType;
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message: mg, type: tp});
            }
          })
          .catch(res => {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message: mg, type: tp});
          });
    },
    // 刷新
    buttonRefresh() {
      this.searchForm = {
        requ_no: null,
        requ_stff_id: null,
        requ_receive: null,
        cust_country: null,
        stff_name: null,
        cust_name: null,
        status: null,
        timeValue: {
          startTime: null,
          endTime: null
        },
        timeValue1: {
          startTime: null,
          endTime: null
        },
        requ_status: null
      };
      if (this.isDyj) {
        this.searchForm.belo_dept_id = this.beloDeptId;
      } else {
        this.searchForm.belo_dept_id = null;
        this.$refs.selectSectionType.value2 = '全部';
      }
      this.loadFlag = true;
      // this.$refs.selectStatus.changeValue();
      this.currentPage = 1;
      this.initData();
      this.$refs.pubPagination.currentPage = 1;
    },
    // 查询方法
    getRequsNow() {
      this.loadFlag = true;
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.getRequsList();
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    // 状态查询
    getStatusVal(val) {
      this.searchForm.requ_status = val;
    },
    // 判断是否可以删除
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      const permId = this.$route.query.perm_id;
      this.jump('/requ_edit', {perm_id: permId, form_id: row.requ_id});
    },
    // //新增
    addRow() {
      const permId = this.$route.query.perm_id;
      this.jump(`/requ_add?perm_id=${permId}`);
    },
    // 分页查询
    changePageSearch(val) {
      this.currentPage = val;
      this.getRequsList();
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time);
    },
    formDeliTime(row) {
      return this.helper.toTimeDay(row.requ_deli_time);
    },
    // 未选中提示
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({type: 'warning', message: '请至少选择一条数据！'});
      } else {
        let temp = false;
        for (let i = 0; i < this.multiSelection.length; i++) {
          if (this.multiSelection[i].status === 2) {
            temp = true;
            break;
          }
        }
        if (temp) {
          this.$message({type: 'warning', message: '已生效的数据不可删除！'});
          this.$refs.multiTable.clearSelection();
        } else {
          this.mBox();
        }
      }
    },
    //供应商信息list删除提示
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            const ids = [];
            this.multiSelection.map(item => {
              ids.push(item.requ_id);
            });
            post(requAPI.deleteRequByIds, {requ_id_list: ids})
                .then(res => {
                  let mg = res.data.msg;
                  if (res.data.code === 0) {
                    let tp = 'success';
                    this.$message({message: mg, type: tp});
                    if (ids.length === this.tableData.length) {
                      if (this.currentPage > 1) {
                        this.currentPage = this.currentPage - 1;
                        this.$refs.pubPagination.currentPage = this.currentPage;
                      }
                    }
                    this.initData();
                  } else {
                    let tp = 'error';
                    this.$message({message: mg, type: tp});
                    this.initData();
                  }
                })
                .catch(res => {
                  let mg = res.data.msg;
                  let tp = 'error';
                  this.$message({message: mg, type: tp});
                  this.initData();
                });
          })
          .catch(() => {
            let mg = '取消删除！';
            let tp = 'info';
            this.$message({message: mg, type: tp});
          });
    },
    //查询范围
    getStatusVal2(val) {
      this.searchForm.belo_dept_id = val;
    },
    //升序 降序
    sortChange(val) {
      if (val.order === 'ascending') {
        this.searchForm.requ_sort = 'asc';
        if (val.prop === 'requ_stff_name') {
          this.searchForm.requ_field = 'CONVERT(requ_stff_name using GBK)';
        } else {
          this.searchForm.requ_field = val.prop;
        }
      } else if (val.order === 'descending') {
        this.searchForm.requ_sort = 'desc';
        if (val.prop === 'requ_stff_name') {
          this.searchForm.requ_field = 'CONVERT(requ_stff_name using GBK)';
        } else {
          this.searchForm.requ_field = val.prop;
        }
      } else {
        this.searchForm.requ_sort = '';
        this.searchForm.requ_field = '';
      }
      this.searchForm.page_no = 1;
      this.initData();
    },
    async getSyncData() {
      await (this.syncLoadFlag = true);
      let searchForm = this.$refs.searchTable.searchForm;
      let [startTime, endTime] = this.dateRange;
      if (startTime) searchForm.startTime = startTime / 1000;
      if (endTime) searchForm.endTime = endTime / 1000;
      let {data} = await getNoCatch('/api/pc/getGcdydByName', searchForm);
      this.syncDataTotalPage = data.total;
      this.syncTableData = data.list;
      this.syncLoadFlag = false;
    },
    jumpAddNewSyncData(row) {
      row.requ_deli_time *= 1000;
      this.syncDataDialogVisible = false;
      this.$router.push({
        name: 'RequAdd',
        query: {perm_id: this.$route.query.perm_id, autoInc: row.autoInc},
        params: {syncData: row}
      });
    },
    syncDataRefresh() {
      this.dateRange = [];
      this.$refs.searchTable.resetFields();
      this.getSyncData();
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}

.vd_total {
  text-align: right;
}

.vd_left {
  margin-left: 10px;
}

.topDateRangeSearch {
  position: relative;
  top: 1px;
  width: 300px !important;
}
</style>
